import * as Sentry from '@sentry/sveltekit';
import { type Writable, writable } from "svelte/store";
import httpCommon from "./lib/services/http-common";

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
    dsn: "https://a8bf51e7c39b12722e4e0fa581cd0feb@o4508370988498944.ingest.us.sentry.io/4508370990202880",
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    integrations: [Sentry.replayIntegration()]
})

interface BlogArticlePaging {
    limit: number;
    offset: number;
    total: { pages: number; articles: number }
}


export interface UseI8sBlogArticlePagination {
    data: Writable<'loading'| App.BlogArticle[]>;
    paging: Writable<BlogArticlePaging>;
    needsRefresh: Writable<boolean>;
}

export const useI8sBlogArticlePagination =  (records_per_page = 40): UseI8sBlogArticlePagination => {
    const needsRefresh = writable<boolean>(true);
    const data = writable<'loading'| App.BlogArticle[]>('loading');
    const paging = writable<BlogArticlePaging>({
        limit: records_per_page,
        offset: 0,
        total: {
            articles: records_per_page,
            pages: 1
        }
    });


    const getCurrentPaging = async () => {
        return await new Promise<BlogArticlePaging>((resolve) => {
            paging.subscribe((value) => {
                resolve(value);
            })
        })
    }
// DESC 57,31,10,6,12,52,53,37,22
// ASC 57,31,10,6,12,52,53,37,22
    async function fetchData() {
        const currentPaging = await getCurrentPaging();
        const fields = `summary,title,code,cover_image_url,publication_date,insert_sequence,tags`;
        const response = await httpCommon.get(`/blog_articles?fields=all&sort=insert_sequence,DESC&limit=${currentPaging.limit}&offset=${currentPaging.offset}`);

        const responseData = await response.data;
        data.set(responseData['blog_articles']);
        const responsePaging = responseData.paging;

        paging.set({
            limit: responsePaging.page_size,
            offset: (responsePaging.current_page - 1) * responsePaging.page_size || 0,
            total: {
                articles: +responsePaging.matching_items,
                pages: +responsePaging.total_pages
            }
        });
    }

    needsRefresh.subscribe(async (value) => {
        if (value === true) {
            await fetchData().then(() => {
                needsRefresh.set(false);
            });
        }
    });



    return {
        data,
        paging,
        needsRefresh
    }
}
export const handleError = Sentry.handleErrorWithSentry();